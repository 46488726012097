import axios from "axios";
import { BackendBaseApi } from "../../constants/constants";
import { eraseCookie } from "../../utility/cookies";
export const USER_LOGOUT = "USER_LOGOUT";
export const LOGGED_IN = "LOGGED_IN";
export const GET_THEME_DETAILS = "GET_THEME_DETAILS";
export const COLLEGE_DETAILS = "COLLEGE_DETAILS";
export const IS_EDUCATION_GROUP = "IS_EDUCATION_GROUP";
export const IS_GRADE_SYSTEM = "IS_GRADE_SYSTEM";
export const MAIN_DETAILS_ALL = "MAIN_DETAILS_ALL";

export const getThemeDetails = () => {
  return async (dispatch) => {
    const res = await axios
      .get(BackendBaseApi.PRAVESH + "api/saas/domain_detail/", {
        withCredentials: true,
      })
      .catch((error) => {
        throw error;
      });
    var data = {};

    // if (res.data.success) {
    data = {
      main: res.data.primary_color,
      hover: "#0069D9",
      disable: "#58AAFF",
      contrast_text: "#fff",
      secondary: res.data.secondary_color,
      college_name: res.data.college_name,
      side_image: res.data.side_image,
      logo: res.data.logo,
    };
    // } else {
    //   // alert.error("Some Error Occurred!!!");
    // }
    dispatch({ type: LOGGED_IN, payload: res.data.is_loggedin });
    dispatch({
      type: IS_EDUCATION_GROUP,
      payload: res.data.is_education_group,
    });
    dispatch({ type: GET_THEME_DETAILS, payload: data });
    dispatch({ type: COLLEGE_DETAILS, payload: res.data.college_details });
    if (!res.data.is_education_group)
      dispatch({
        type: IS_GRADE_SYSTEM,
        payload: res?.data?.college_details?.is_grade_system,
      });
    dispatch({ type: MAIN_DETAILS_ALL, payload: res.data });
  };
};

export const LogoutAPICall = (history, alert, appFirstLoadRef = {}) => {
  return async (dispatch) => {
    axios
      .post(BackendBaseApi.PRAVESH + "api/saas/logout/", null, {
        withCredentials: true,
      })
      .then((res) => {
        if (res.data.success) {
          dispatch({
            type: LOGGED_IN,
            payload: false,
          });
          dispatch({
            type: USER_LOGOUT,
            payload: {},
          });
          history.push("/");
          eraseCookie("college_id");
          eraseCookie("user_id");
          appFirstLoadRef.current = true;
        } else alert.error("Some Error Occurred!!!");
      })
      .catch((err) => {
        history.push("/");
        alert.error("Some Error Occurred!!!");
        throw err;
      });
  };
};

// export default Logout;
