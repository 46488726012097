import createBrowserHistory from "history/createBrowserHistory";
import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { applyMiddleware, createStore } from "redux";
import thunk from "redux-thunk";
import logger from "redux-logger";
import "../src/style/button.css";
import "../src/style/index.css";
import "../src/style/reactVideo.css";
//import "../src/style/global.scss";
import App from "./App/App";
import reportWebVitals from "./reportWebVitals";
import RootReducer from "./RootReducer/RootReducer";
import ScrollToTop from "./scrollToTop";
import { ErrorBoundary } from "react-error-boundary";
import { makeStyles } from "@material-ui/core/styles";
import { Button, Typography } from "@material-ui/core";
import errorsvg from "../src/assets/svg/No-data-rafiki.svg";
//import "react-modal-video/scss/modal-video.scss";
import Layout from "./components/Layout";

import {
  positions,
  Provider as AlertProvider,
  transitions,
  useAlert,
} from "react-alert";
import AlertTemplate from "./components/AlertTempelate";

export const history = createBrowserHistory();

//REACT-ALERT DESIGN AND OPTIONS
const options = {
  // you can also just use 'bottom center'
  position: positions.TOP_RIGHT,
  timeout: 5000,
  offset: "30px",
  // you can also just use 'scale'
  transition: transitions.SCALE,
  containerStyle: {
    zIndex: 10001,
    fontSize: "14px",
    textTransform: "lowercase",
  },
};

const store = createStore(RootReducer, applyMiddleware(thunk, logger));

const useStyles = makeStyles((theme) => ({
  btn: {
    borderRadius: "8px",
    boxShadow: "none",
    paddingLeft: "30px",
    paddingRight: "30px",
  },
  img: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
}));

function ErrorFallback({ error, resetErrorBoundary }) {
  const classes = useStyles();
  return (
    <div className={classes.img}>
      <img src={errorsvg} height="400px" width="400px" alt="404 Error" />
      <Typography
        variant="h1"
        style={{ fontWeight: "bold", marginBottom: "20px" }}
      >
        Something went Wrong
      </Typography>
      <pre>{error.message}</pre>
      <br />
      <br />
      <Button className={classes.btn} variant="contained" color="primary">
        Try Again
      </Button>
    </div>
  );
}

ReactDOM.render(
  // <React.StrictMode>
  <Provider store={store}>
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <BrowserRouter>
        <AlertProvider template={AlertTemplate} {...options}>
          <App />
        </AlertProvider>
      </BrowserRouter>
    </ErrorBoundary>
  </Provider>,
  // </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
