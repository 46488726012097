import { Button, Grid, Paper, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useEffect, useState } from "react";
import { useAlert } from "react-alert";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory, useLocation } from "react-router-dom";
import {
  collegeDetailSelectorFn,
  collegeGroupListSelectorFn,
  entityInfoSelectorFn,
  isCollegeGroupFn,
  isEducationGroupFn,
  collegeIdFn,
} from "../../api/SignIn/selector";
import {
  EDGUniversityAllCollegeAPI,
  EDGUniversityDashboardAPI,
  EDGUniversityDegreeAPI,
  EDGUniversityIndustryAPI,
  EDGUniversityPlacementAPI,
  EDGUniversitySkillsAPI,
} from "../../api/University/APIs/action";
import CampusPlacement from "../../components/Cards/UniversityGraph/BarChart/CampusPlacement";
import Degree from "../../components/Cards/UniversityGraph/BarChart/Degree";
import HiredPie from "../../components/Cards/UniversityGraph/PieChart/HiredPie";
import CustomCircularProgress from "../../components/CircularProgressBar";
import GroupListDropdown from "../../components/Input/DropDownButton/GroupListDropdown";
import NoDataFunc from "../../components/NoDataSVG/index1";
import UniversityTable from "../../components/Table/UniversityTable/EligibleTable";
import { checkForBreadcrumb } from "../../utility/checkBreadcrumbs";
import { generateCsvForParams } from "../../utility/commaSeparatedStrings";
import UniversityInfo from "../Dashboard/UniversityInfo";
import useCollegeGroupList from "../../Hooks/useCollegeGroupList";
import useNavBarCollegeGroupList from "../../Hooks/useNavBarCollegeGroupList";

const useStyles = makeStyles((theme) => ({
  Chip: {
    backgroundColor: "#FAFBFC",
    //   padding: "20px",
    display: "flex",
    flexDirection: "row",
    borderRadius: "8px",
    //  width: "150px",
    paddingLeft: "20px",
    paddingRight: "20px",
    height: "40px",
    color: "#8a94a6",
    justifyContent: "space-evenly",
    alignItems: "center",
  },
  dot: {
    backgroundColor: theme.palette.primary.main,
    height: "10px",
    width: "10px",
    borderRadius: "50%",
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    paddingBottom: "20px",
  },
  paper: {
    boxShadow: "rgb(0 0 0 / 10%) 0px 8px 24px",
    borderRadius: "8px",
    padding: "30px",
  },
  btn: {
    boxShadow: "none",
    height: "30px",
    borderRadius: "8px",
    display: "flex",
    backgroundColor: "#e0a800",
    color: "#fff",
  },
  item: {
    padding: "20px",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  dotback: {
    display: "flex",
    flexDirection: "row",
    borderRadius: "20px",
    width: "120px",
    height: "40px",
    color: "#8a94a6",
    backgroundColor: "#FAFBFC",
    justifyContent: "space-evenly",
    alignItems: "center",
  },
}));

export default function University() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const alert = useAlert();
  const location = useLocation();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [loadingPlacement, setLoadingPlacement] = useState(false);
  const [loadingAllCollege, setLoadingAllCollege] = useState(false);
  const [loadingDegree, setLoadingDegree] = useState(false);
  const [loadingIndustry, setLoadingIndustry] = useState(false);
  const [loadingSkills, setLoadingSkills] = useState(false);
  const collegeDetail = useSelector(collegeDetailSelectorFn);
  // const [selectedGroup, setSelectedGroup] = useState(null);

  const collegeId = useSelector(collegeIdFn);

  const overview = useSelector((state) => state?.EDGCommon?.edgDashboard);
  const placements = useSelector(
    (state) => state?.EDGCommon?.edgDashboardPlacements
  );
  const degrees = useSelector((state) => state?.EDGCommon?.edgDashboardDegree);
  const skills = useSelector((state) => state?.EDGCommon?.edgDashboardSkills);
  const industry = useSelector(
    (state) => state?.EDGCommon?.edgDashboardIndustry
  );
  const collegeTable = useSelector((state) =>
    state?.EDGCommon?.edgDashboardAllCollege
      ? state?.EDGCommon?.edgDashboardAllCollege
      : []
  );
  const collegeGroupList = useSelector(collegeGroupListSelectorFn);
  const isEducationGroup = useSelector(isEducationGroupFn);
  const isCollegeGroup = useSelector(isCollegeGroupFn);
  const entityInfo = useSelector(entityInfoSelectorFn) || {
    college: "College",
    university: "University",
  };

  const checkSelectedGroup = () => {
    if (isCollegeGroup) {
      if (!selectedGroup && collegeGroupList?.length === 1)
        return `&group_id=${collegeGroupList[0]?.id}`;
      else if (selectedGroup && selectedGroup.id !== "All")
        return `&group_id=${selectedGroup.id}`;
      else
        return `&group_id=${generateCsvForParams(
          collegeGroupList?.map((item) => item.id)
        )}`;
    } else {
      if (selectedGroup && selectedGroup.id !== "All")
        return `&group_id=${selectedGroup.id}`;
      else if (collegeDetail?.education_group_id)
        return `&education_group_id=${collegeDetail?.education_group_id}`;
    }
    return "";
  };

  // const [newCollegeGroupList, setNewCollegeGroupList] = useState([]);

  // useEffect(() => {
  //   if (collegeGroupList?.length > 1 && isCollegeGroup) {
  //     setNewCollegeGroupList(
  //       [{ id: "All", name: "All Groups" }].concat(collegeGroupList)
  //     );

  //   } else {
  //     setNewCollegeGroupList(collegeGroupList);

  //   }
  // }, [collegeGroupList?.length, isCollegeGroup]);

  const {
    newCollegeGroupList,
    setNewCollegeGroupList,
    selectedGroup,
    setSelectedGroup,
  } = useCollegeGroupList(collegeGroupList, isCollegeGroup);

  useEffect(() => {
    let groupId = checkSelectedGroup();
    groupId = groupId.replace(/^(&|=)+/, "");
    groupId = groupId.replace(/(&|=)+$/, "");
    if (groupId) {
      dispatch(
        EDGUniversityDashboardAPI(setLoading, alert, history, location, groupId)
      );
      dispatch(
        EDGUniversityPlacementAPI(
          setLoadingPlacement,
          alert,
          history,
          location,
          groupId
        )
      );
      dispatch(
        EDGUniversityAllCollegeAPI(
          setLoadingAllCollege,
          alert,
          history,
          location,
          groupId
        )
      );
      dispatch(
        EDGUniversityDegreeAPI(
          setLoadingDegree,
          alert,
          history,
          location,
          groupId
        )
      );
      dispatch(
        EDGUniversityIndustryAPI(
          setLoadingIndustry,
          alert,
          history,
          location,
          groupId
        )
      );
    }
  }, [selectedGroup]);

  useEffect(() => {
    if (collegeDetail?.education_group_id)
      dispatch(
        EDGUniversitySkillsAPI(
          setLoadingSkills,
          alert,
          history,
          location,
          collegeDetail?.education_group_id
        )
      );
  }, [collegeDetail?.education_group_id]);

  useNavBarCollegeGroupList(selectedGroup, setLoading);

  return (
    // <UniversityLayout>
    <>
      {collegeGroupList?.length > 0 && (
        <div style={{ marginTop: "10px", marginBottom: "27px" }}>
          <GroupListDropdown
            title="Group"
            state={selectedGroup}
            setState={setSelectedGroup}
            groupList={newCollegeGroupList}
          />
        </div>
      )}
      {loading ? (
        <CustomCircularProgress />
      ) : (
        <>
          <UniversityInfo data={overview} />
          <Grid container spacing={4} style={{ marginTop: "20px" }}>
            <Grid item xs={12}>
              <Paper className={classes.paper}>
                {loadingPlacement ? (
                  <CustomCircularProgress />
                ) : (
                  <>
                    <div className={classes.header}>
                      <Typography variant="h6" style={{ color: "#000" }}>
                        {`Campus Placement ${entityInfo?.college} Wise`}
                      </Typography>
                      <div className={classes.Chip}>
                        <div
                          className={classes.dot}
                          style={{
                            backgroundColor: "#138496",
                          }}
                        />
                        <Typography
                          variant="overline"
                          style={{ marginLeft: "12px" }}
                        >
                          No. Of Students Placed
                        </Typography>
                      </div>
                    </div>
                    {placements?.categories?.length > 0 ? (
                      <CampusPlacement
                        data={placements}
                        isEducationGroup={isEducationGroup}
                        isCollegeGroup={isCollegeGroup}
                        entityInfo={entityInfo}
                      />
                    ) : (
                      <>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center",
                            width: "100%",
                          }}
                        >
                          <NoDataFunc width={"300Px"} height={"200px"} />
                          <Typography style={{ fontSize: "16px" }}>
                            {" "}
                            No Data Available
                          </Typography>{" "}
                        </div>
                      </>
                    )}
                  </>
                )}
              </Paper>
            </Grid>
            <Grid item xs={12}>
              <div>
                <Typography
                  variant="h6"
                  style={{ paddingBottom: "20px", color: "#000" }}
                >
                  {`All ${entityInfo?.college} Details in a single view`}
                </Typography>
                <UniversityTable
                  data={collegeTable}
                  loading={loadingAllCollege}
                />
              </div>
            </Grid>
            <Grid item xs={7}>
              <Paper className={classes.paper}>
                {loadingDegree ? (
                  <CustomCircularProgress />
                ) : degrees?.categories?.length > 0 ? (
                  <>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <Typography variant="h6" style={{ color: "#000" }}>
                        Degree wise Campus Placement
                      </Typography>
                      <Link
                        to={checkForBreadcrumb(
                          isEducationGroup,
                          isCollegeGroup,
                          collegeId,
                          "degree-graph"
                        )}
                        style={{ textDecoration: "none" }}
                      >
                        <Button variant="contained" className={classes.btn}>
                          View All
                        </Button>
                      </Link>
                    </div>

                    <div
                      style={{
                        display: "flex",
                        borderBottom: ".5px solid #f3f3f3",
                      }}
                    >
                      <div className={classes.item}>
                        <div className={classes.dotback}>
                          <div
                            className={classes.dot}
                            style={{
                              backgroundColor: "#377dff",
                            }}
                          />
                          <Typography variant="overline">
                            Total Students
                          </Typography>
                        </div>
                      </div>
                      <div className={classes.item}>
                        <div className={classes.dotback}>
                          <div
                            className={classes.dot}
                            style={{
                              backgroundColor: "#38cb89",
                            }}
                          />
                          <Typography variant="overline">
                            Placed Student
                          </Typography>
                        </div>
                      </div>
                    </div>
                    <Degree data={degrees} />
                    <Typography
                      variant="body2"
                      style={{ fontWeight: "bold", textAlign: "center" }}
                    >
                      Number of Applicants
                    </Typography>
                  </>
                ) : (
                  <>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <Typography variant="h6" style={{ color: "#000" }}>
                        Degree wise Campus Placement
                      </Typography>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "100%",
                      }}
                    >
                      <NoDataFunc width={"300Px"} height={"200px"} />
                      <Typography style={{ fontSize: "16px" }}>
                        {" "}
                        No Data Available
                      </Typography>{" "}
                    </div>
                  </>
                )}
              </Paper>
            </Grid>
            <Grid item xs={5}>
              {skills && Object.keys(skills)?.length > 0 && (
                <HiredPie
                  data={skills}
                  title={"Highlighted Skills of Students"}
                  link={"/educational-group/skills"}
                />
              )}
              <br />
              {/* <HighlightedIndustry /> */}
              {industry && Object.keys(industry)?.length > 0 && (
                <HiredPie
                  data={industry}
                  title={"Highlighted Industry"}
                  link={"/educational-group/industry"}
                />
              )}
            </Grid>
          </Grid>
        </>
      )}
    </>
    // </UniversityLayout>
  );
}
